import React, { useEffect } from "react";
import WhyContactUs from "./WhycontactUs";
import Faq from "../Home/Faq";
import Testimonial from "../Home/Testimonial";

function AboutUs() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <>

            <div className="  aboutMain py-12 md:py-24 lg:py-32">
                <div>
                    <h1 className=" py-4 text-center text-[28px] text-white font-Montserrat font-medium ">About us</h1>
                    <p className=" text-[30px] font-bold text-white text-center font-Varela">Unlocking Financial Freedom: CanuckCash Leads the Way in Reliable Loan Solutions.</p>

                </div>
            </div>


            <WhyContactUs />

            <div className="anima py-12">

                <Faq />
            </div>
            <div className="anima text-[20px] md:text-[30px] text-center font-semibold font-Montserrat  text-primary">
                <p>Take it from us. Better yet, Our customers.</p>
            </div>

            <div className="anima grid  grid-cols-1 md:grid-cols-3 py-10  gap-5 md:gap-4 lg:gap-10 md:px-5 px-5 lg:px-32 ">

            <Testimonial image='./static/img/rev1.jpg' content="CanuckCash made borrowing a breeze! Their online application was simple and quick. Within minutes, I had the funds I needed deposited into my account. Highly recommend!" name="Olivia Wilson" workingProfession="Digital Marketer"/>
               

                <Testimonial image='./static/img/rev2.jpg' content="I was in a financial pinch and CanuckCash came through for me. The entire process was smooth, and their customer service was top-notch. Will definitely use them again if needed." name="Leslie Alexander" workingProfession="Freelance React Developer" />
               

                <Testimonial image='./static/img/rev3.jpg' content="Thanks to CanuckCash, I was able to cover unexpected expenses without the stress. The application was straightforward, and I received approval fast. Great service and highly efficient!" name="Ethan Thompson" workingProfession="Software Engineer"/>


            </div>
        </>
    )
}

export default AboutUs;