import React from "react";
import { Outlet } from "react-router";
import Step1 from "./Step1";
import Step2 from "./Step2";
function ApplyNow() {
    return (
        <>
 
            <Outlet />
        </>
    )
}

export default ApplyNow;